!(function (a) {
    function b(b) {
        var h,
            c = a(this),
            d = null,
            e = [],
            f = null,
            g = null,
            i = a.extend(
                {
                    rowSelector: "> li",
                    nonRowSelector: "*",
                    submenuSelector: null,
                    submenuDirection: "right",
                    tolerance: 75,
                    delayInitialOpen: !1,
                    enter: a.noop,
                    exit: a.noop,
                    activate: a.noop,
                    deactivate: a.noop,
                },
                b,
            ),
            j = 3,
            k = 200,
            l = function () {
                g && (clearTimeout(g), (h = !1));
            },
            m = function (a) {
                e.push({ x: a.pageX, y: a.pageY }), e.length > j && e.shift();
            },
            n = function () {
                l(), s(null);
            },
            o = function () {
                l(), i.enter(this), s(this);
            },
            p = function () {
                d || l(), i.exit(this);
            },
            q = function () {
                r(this);
            },
            r = function (a) {
                a != d && (d && i.deactivate(d), null != a && i.activate(a), (d = a));
            },
            s = function (a) {
                var b = t();
                b
                    ? (g = setTimeout(function () {
                          s(a);
                      }, b))
                    : r(a);
            },
            t = function () {
                function p(a, b) {
                    return (b.y - a.y) / (b.x - a.x);
                }
                if (!d) return i.delayInitialOpen ? (h ? ((h = !1), 0) : ((h = !0), k)) : 0;
                if (!a(d).is(i.nonRowSelector) || null == i.submenuSelector) return 0;
                var b = a(d).find(i.submenuSelector);
                if (0 == b.length) return 0;
                var c = b.offset(),
                    g = { x: c.left, y: c.top - i.tolerance },
                    j = { x: c.left + b.outerWidth(), y: g.y },
                    l = { x: c.left, y: c.top + b.outerHeight() + i.tolerance },
                    m = { x: c.left + b.outerWidth(), y: l.y },
                    n = e[e.length - 1],
                    o = e[0];
                if (!n) return 0;
                if ((o || (o = n), f && n.x == f.x && n.y == f.y)) return 0;
                var q = g,
                    r = l;
                "left" == i.submenuDirection
                    ? ((q = j), (r = m))
                    : "below" == i.submenuDirection
                    ? ((q = j), (r = g))
                    : "above" == i.submenuDirection && ((q = l), (r = m));
                var s = p(n, q),
                    t = p(n, r),
                    u = p(o, q),
                    v = p(o, r);
                return s < u && t > v ? ((f = n), k) : ((f = null), 0);
            };
        c.mouseleave(n).find(i.rowSelector).mouseenter(o).mouseleave(p).click(q), a(document).mousemove(m);
    }
    a.fn.menuAim = function (a) {
        return (
            this.each(function () {
                b.call(this, a);
            }),
            this
        );
    };
})(jQuery);
